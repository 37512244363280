import React from "react";
import {Row, Container, Col, Button, ButtonGroup, ToggleButton, Alert} from "react-bootstrap";
import './style.css';
import '../style.css';
import {FaWalking, FaCircle, FaTrafficLight} from 'react-icons/fa';
import {BiTrain, BiChip, BiTimer, BiArrowBack, BiRightArrowAlt} from 'react-icons/bi';
import {AiOutlineControl, AiTwotoneSetting, AiOutlineSchedule, AiOutlineFileExclamation, AiOutlinePoweroff, AiOutlineDownload} from 'react-icons/ai';
import {RiLayoutMasonryFill, RiCloseCircleLine} from 'react-icons/ri';

import crossRoad from '../../resources/cross-road.png';
import lightGreen from '../../resources/light-green.png';
import lightRed from '../../resources/light-red.png';
import lightNone from '../../resources/light-off.png';
import lightYellow from '../../resources/light-yellow.png';

import lightLeftGreen from '../../resources/light-left-green.png';
import lightLeftRed from '../../resources/light-left-red.png';
import lightLeftNone from '../../resources/light-left-none.png';
import lightLeftYellow from '../../resources/light-left-yellow.png';

import lightLandGreen from '../../resources/light-land-green.png';
import lightLandRed from '../../resources/light-land-red.png';
import lightLandNone from '../../resources/light-land-off.png';
import lightLandYellow from '../../resources/light-land-yellow.png';

import counterLight from '../../resources/counter-light.png';

import walkingRed from '../../resources/walking-red.png';
import walkingGreen from '../../resources/walking-green.png';
import walkingOff from '../../resources/walking-off.png'
import lightOff from '../../resources/light-off.png';
import navBar from '../../resources/navbar.png';
import avatar from "../../resources/admin-icon.jpg";
import railwayOn from '../../resources/railway-on.png';
import railwayOff from '../../resources/railway-off.png';
import turnLeftOn from '../../resources/turn-left-on.png';
import turnLeftOff from '../../resources/turn-left-off.png';
import turnRightOn from '../../resources/turn-right-on.png';
import turnRightOff from '../../resources/turn-right-off.png';
import goAheadOff from '../../resources/go-ahead-off.png';
import CityTopView from '../../resources/city-top-view.jpg';
import ListGroup from "react-bootstrap/ListGroup";
import Draggable from "react-draggable";
import DateTimePicker from "react-datetime-picker";
import {config} from "../Config";
import {save} from "save-file";
import {Schedule} from "./Schedule";

// const { ipcRenderer } = require("electron");
//
// function openWindow () {
//   console.log("to be send to ipc")
//   ipcRenderer.send('asynchronous-message', 'createNewWindow');
// }

Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

const colorGREEN = '#66FF3F';
const colorRED = '#FF0909';
const colorYELLOW = '#FFD600';

export const SIGN_SETTING_DEFAULT = [
  {
    label: {
      text: 'Tuyến Đinh Tiên Hoàng',
      x: 68,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 220,
      y: 448
    },
    countdown: {
      channel: 1,
      x: 350,
      y: 448
    },
    walking: {
      channel: 1,
      x: 295,
      y: 393
    },
    railway: {
      channel: 1,
      x: 295,
      y: 338
    },
    turnLeft: {
      channel: 1,
      x: 350,
      y: 283
    },
    turnRight: {
      channel: 1,
      x: 350,
      y: 393
    },
    goAhead: {
      channel: 1,
      x: 350,
      y: 338
    },
  },
  {
    label: {
      text: 'Tuyến 2',
      x: 475,
      y: 655,
    },
    trafficLight: {
      channel: 1,
      x: 610,
      y: 563
    },
    countdown: {
      channel: 1,
      x: 610,
      y: 508
    },
    walking: {
      channel: 1,
      x: 555,
      y: 563
    },
    railway: {
      channel: 1,
      x: 500,
      y: 563
    },
    turnLeft: {
      channel: 1,
      x: 445,
      y: 508
    },
    turnRight: {
      channel: 1,
      x: 555,
      y: 508
    },
    goAhead: {
      channel: 1,
      x: 500,
      y: 508
    },
  },
  {
    label: {
      text: 'Tuyến 3',
      x: 885,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 720,
      y: 248
    },
    countdown: {
      channel: 1,
      x: 665,
      y: 248
    },
    walking: {
      channel: 1,
      x: 720,
      y: 303
    },
    railway: {
      channel: 1,
      x: 720,
      y: 358
    },
    turnLeft: {
      channel: 1,
      x: 665,
      y: 303
    },
    turnRight: {
      channel: 1,
      x: 665,
      y: 413
    },
    goAhead: {
      channel: 1,
      x: 665,
      y: 358
    },
  },
  {
    label: {
      text: 'Tuyến 4',
      x: 485,
      y: 20,
    },
    trafficLight: {
      channel: 1,
      x: 402,
      y: 68
    },
    countdown: {
      channel: 1,
      x: 402,
      y: 193
    },
    walking: {
      channel: 1,
      x: 457,
      y: 138
    },
    railway: {
      channel: 1,
      x: 512,
      y: 138
    },
    turnLeft: {
      channel: 1,
      x: 457,
      y: 193
    },
    turnRight: {
      channel: 1,
      x: 567,
      y: 193
    },
    goAhead: {
      channel: 1,
      x: 512,
      y: 193
    },
  },
  {
    label: {
      text: 'Tuyến Đinh Tiên Hoàng',
      x: 68,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 220,
      y: 448
    },
    countdown: {
      channel: 1,
      x: 350,
      y: 448
    },
    walking: {
      channel: 1,
      x: 295,
      y: 393
    },
    railway: {
      channel: 1,
      x: 295,
      y: 338
    },
    turnLeft: {
      channel: 1,
      x: 350,
      y: 283
    },
    turnRight: {
      channel: 1,
      x: 350,
      y: 393
    },
    goAhead: {
      channel: 1,
      x: 350,
      y: 338
    },
  },
  {
    label: {
      text: 'Tuyến 2',
      x: 475,
      y: 655,
    },
    trafficLight: {
      channel: 1,
      x: 610,
      y: 563
    },
    countdown: {
      channel: 1,
      x: 610,
      y: 508
    },
    walking: {
      channel: 1,
      x: 555,
      y: 563
    },
    railway: {
      channel: 1,
      x: 500,
      y: 563
    },
    turnLeft: {
      channel: 1,
      x: 445,
      y: 508
    },
    turnRight: {
      channel: 1,
      x: 555,
      y: 508
    },
    goAhead: {
      channel: 1,
      x: 500,
      y: 508
    },
  },
  {
    label: {
      text: 'Tuyến 3',
      x: 885,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 720,
      y: 248
    },
    countdown: {
      channel: 1,
      x: 665,
      y: 248
    },
    walking: {
      channel: 1,
      x: 720,
      y: 303
    },
    railway: {
      channel: 1,
      x: 720,
      y: 358
    },
    turnLeft: {
      channel: 1,
      x: 665,
      y: 303
    },
    turnRight: {
      channel: 1,
      x: 665,
      y: 413
    },
    goAhead: {
      channel: 1,
      x: 665,
      y: 358
    },
  },
  {
    label: {
      text: 'Tuyến 4',
      x: 485,
      y: 20,
    },
    trafficLight: {
      channel: 1,
      x: 402,
      y: 68
    },
    countdown: {
      channel: 1,
      x: 402,
      y: 193
    },
    walking: {
      channel: 1,
      x: 457,
      y: 138
    },
    railway: {
      channel: 1,
      x: 512,
      y: 138
    },
    turnLeft: {
      channel: 1,
      x: 457,
      y: 193
    },
    turnRight: {
      channel: 1,
      x: 567,
      y: 193
    },
    goAhead: {
      channel: 1,
      x: 512,
      y: 193
    },
  }
]

export const STATION_VALUE_DEFAULT = {
  isOnline: false,
  stationId: null,
  walkingMode: 0,
  trainWayMode: 1,
  activeTimeBegin: '00:00',
  activeTimeEnd: '00:00',
  control: {
    lane: 1,
    onOff: false,
  },
  stationInfo: {
    stationName: 'Ngã tư Võ Thị Sáu',
    hardwareId: 'GW-12:23:34:45',
    type: 'DC 24V',
    numPhase: 3,
    isVarient: false,
    cpuTime: '12:34',
    activeTime: '00:00 23:00',
    signSetting: SIGN_SETTING_DEFAULT,
    backgroundSrc: null,
    nCard: 2,
  },
  outputSignal: [0, 0, 0, 0],
  timer: {
    setting: [
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      }
    ],
      current:
      [
        {
          index: 1,
          trafficLight: 'green',
          walking: 'red',
          mappingLight: 'green',
          counter: 23,
        },
        {
          index: 2,
          trafficLight: 'red',
          walking: 'green',
          mappingLight: 'red',
          counter: 25,
        },
      ]
  },
  logMessage: "[I] Device reached stable state",
  errorStatus: 0,
  noteList: [],
  diaryText: '',
  accessories: []
};

const ACTION_MODE = {
  NONE: 0,
  MONITOR: 1,
  STATION_CONFIG: 2,
  DIARY: 3,
  SCHEDULE: 4,
}

const SIGN_TYPE = {
  NONE: 0,
  COUNTDOWN: 1,
  TRAFFIC_LIGHT: 2,
  WALKING: 3,
  RAILWAY: 4,
  TURN_LEFT: 5,
  GO_AHEAD: 6,
  TURN_RIGHT: 7,
  LABEL: 8,
};

const SAMPLE_DEVICE = [
  {
    name: 'Đèn xanh D100',
    model: 'D100:12:33:22',
    by: 'Sitech',
    posNum: 'Trụ 1',
    position: 'Trên thân',
    installDate: '10/10/2020',
    state: 'Đang hoạt động'
  },
  {
    name: 'Đèn đếm ngược',
    model: 'DN00:12:33:22',
    by: 'Sitech',
    posNum: 'Trụ 2',
    position: 'Tay vươn',
    installDate: '10/10/2020',
    state: 'Đang hoạt động'
  },
]

let date = new Date();
date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
let timeToSet = date.toISOString().slice(0, 10);

const DEFAULT_ACCESSORY_FORM = {
  name: '',
  model: '',
  madeBy: '',
  position: '',
  installedPosition: '',
  installedDate: timeToSet,
  state: 'Đang hoạt động',
}

class DraggableSign extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      x: props.x,
      y: props.y,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      state => {
        state.x = nextProps.x;
        state.y = nextProps.y;
        return state;
      }
    );
    // }
  }

  render() {
    return <Draggable
      axis="both"
      // handle=".handle"
      defaultPosition={null}
      position={{x: this.state.x, y: this.state.y}}
      grid={[5, 5]}
      scale={1}
      onDrag={(e, p) => {if (this.props.onDrag !== null) this.props.onDrag(p.x, p.y)}}
      disabled={this.props.isDisabled}
    >
      <div>
        <div className='handle tag-normal'>
          {this.props.children}
        </div>
      </div>
    </Draggable>
  }
}

export class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangeScheduleMode: false,
      scheduleChangeModel: {
        green: [
          23,
          24,
          24
        ],
        yellow: 3,
        clearance: 3,
      },
      activeTimeBegin: '00:00',
      activeTimeEnd: '00:00',
      mode: ACTION_MODE.MONITOR,
      signSetting: SIGN_SETTING_DEFAULT,
      file: null,
      nCard: 2,
      backgroundSrc: CityTopView,
      radioValue: '1',
      updateDiaryText: '',
      isAddNewDevice: false,
      accessoryForm: {...DEFAULT_ACCESSORY_FORM}
    }
    this.data = props.value || STATION_VALUE_DEFAULT;
    this.onControlLane = props.onControlLane || null;
    this.callbackClose = props.callbackClose || null;
    this.onChangeSchedule = props.onChangeSchedule || null;
    this.onSetActiveTime = props.onSetActiveTime || null;
    this.onSyncTime = props.onSyncTime || null;
    this.submitSignSetting = props.submitSignSetting || null;
    this.changeNumberCard = props.changeNumberCard || null;
    this.updateErrorStatus = props.updateErrorStatus || null;
    this.addAccessory = props.addAccessory || null;
    this.user = props.user || "no name";
    this.submitDiary = props.submitDiary || null;
    this.deleteAccessory = props.deleteAccessory || null;
    this.onGetSchedule = props.onGetSchedule || null;

    this.setState(state => {
      state.activeTimeBegin = this.props.value.activeTimeBegin;
      state.activeTimeEnd = this.props.value.activeTimeEnd;
      return state;
    });

    this.isEditTime = false;

    this.radios = [
      { name: 'Lỗi', value: 1 },
      { name: 'Đã ghi nhận', value: 2 },
      { name: 'Đã xử lý', value: 3 },
    ];

    this.onControlSubmit = this.onControlSubmit.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickChangeSchedule = this.onClickChangeSchedule.bind(this);
    this.onScheduleSettingChange = this.onScheduleSettingChange.bind(this);
    this.onChangeActiveTime = this.onChangeActiveTime.bind(this);
    this.onSubmitActiveTime = this.onSubmitActiveTime.bind(this);
    this.onClickSyncTime = this.onClickSyncTime.bind(this);
    this.onChangeActionMode = this.onChangeActionMode.bind(this);
    this.onSubmitSignSetting = this.onSubmitSignSetting.bind(this);
    this.onChangeSettingChannel = this.onChangeSettingChannel.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.onChangeNumberCard = this.onChangeNumberCard.bind(this);
    this.currentIndex = 0;
    this.onSubmitChangeNumberCard = this.onSubmitChangeNumberCard.bind(this);
    this.setRadioValue = this.setRadioValue.bind(this);
    this.onSubmitDiary = this.onSubmitDiary.bind(this);
    this.onDeleteAccessory = this.onDeleteAccessory.bind(this);
    this.outputSensorList = this.outputSensorList.bind(this);

    console.log("time to set " + timeToSet);
  }

  componentDidMount() {
    // if (this.props.value) {
    //   this.setState(state => state.signSetting = this.props.value.stationInfo.signSetting);
    // }
    this.data = this.props.value;
    console.log("Sign setting " + this.props.value.stationInfo.signSetting);
    this.setState(state => {
      state.activeTimeBegin = this.props.value.activeTimeBegin;
      state.activeTimeEnd = this.props.value.activeTimeEnd;
      return state;
    });
    this.isEditTime = false;
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    // if (nextProps.value !== this.props.value) {
    //   console.log("on uopdate")
      this.setState(
        state => {
          state.signSetting = nextProps.value.stationInfo.signSetting;
          if (this.isEditTime === false &&
            (this.state.activeTimeBegin !== nextProps.value.activeTimeBegin || this.state.activeTimeEnd !== nextProps.value.activeTimeEnd)) {
            state.activeTimeBegin = nextProps.value.activeTimeBegin;
            state.activeTimeEnd = nextProps.value.activeTimeEnd;
          }
          console.log("type of " + nextProps.value.stationInfo.backgroundSrc);
          if (nextProps.value.stationInfo.backgroundSrc !== null &&
              nextProps.value.stationInfo.backgroundSrc.length !== 0) {
            state.backgroundSrc = nextProps.value.stationInfo.backgroundSrc;
          }
          if (this.state.nCard !== nextProps.value.stationInfo.nCard && this.state.mode !== ACTION_MODE.STATION_CONFIG) {
            state.nCard = nextProps.value.stationInfo.nCard;
          }

          return state;
        }
      );
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  onControlSubmit(isPowerOn, index) {
    let curIndex = this.props.value.control.lane;
    console.log("Power " + isPowerOn + " index " + index)
    if (curIndex === index && index !== 0) {
      curIndex = 9;
    } else {
      curIndex = index;
    }
    console.log("on submit " + curIndex);
    if (this.onControlLane != null) {
      this.onControlLane(isPowerOn, curIndex);
    }
  }

  onClickChangeSchedule(command) {
    switch (command) {
      case 'change':
        console.log("on change schedule");
        this.setState(state => {
          state.isChangeScheduleMode = true;
          state.scheduleChangeModel.green[0] = this.props.value.timer.setting[0].greenTime;
          state.scheduleChangeModel.green[1] = this.props.value.timer.setting[1].greenTime;
          state.scheduleChangeModel.green[2] = this.props.value.timer.setting[2].greenTime;
          state.scheduleChangeModel.yellow = this.props.value.timer.setting[0].yellowTime;
          state.scheduleChangeModel.clearance = this.props.value.timer.setting[0].clearanceTime;
          return state;
        });
        break;
      case 'submit':
        console.log("on submit schedule");
        this.setState(state => state.isChangeScheduleMode = false);
        if (this.onChangeSchedule) {
          this.onChangeSchedule(this.state.scheduleChangeModel.green, this.state.scheduleChangeModel.yellow, this.state.scheduleChangeModel.clearance, this.props.value.stationInfo.numPhase);
        }
        break;
      case 'cancel':
        console.log("on cancel schedule");
        this.setState(state => state.isChangeScheduleMode = false);
        break;
    }
    /*if (this.onChangeSchedule != null) {
      this.onChangeSchedule();
    }*/
  }

  onClickClose() {
    // openWindow();
    if (process.env.REACT_APP_MODE !== 'web') {
      window.close();
    } else {
      if (this.callbackClose != null) {
        this.callbackClose();
      }
    }
    this.isEditTime = false;
  }

  statusItem(isConnected, walkingState, trainWayState, powerOn, forceDisable = false) {
    let curStyle = 'rgba(104, 214, 36, 0.79)';
    let text = 'Đang kết nối';
    if (isConnected === false) {
      curStyle = '#EB5757';
      text = 'Mất kết nối';
    }
    return (
      <div style={{minHeight: '5.1em', margin: 'auto'}}>
        <Row style={{padding: '0.5em 2em 0.5em 2em'}}>
          <Col xs={5} style={{
            padding: '0 0.5em 0 0em',
            margin: 'auto'
          }}>
            <Button style={{
              margin: 'auto',
              padding: '1em'
            }}
              variant={powerOn === true ? 'info' : 'secondary'}
              onClick={() => this.onControlSubmit(powerOn !== true, 0)}
              disabled={forceDisable ? false : (this.props.role !== 1 && this.props.role !== 2)}
            >
              <AiOutlinePoweroff size={25} color={'white'}/>
              <p style={{
                margin: 'auto',
                fontSize: '18px',
                textAlign: 'center'
              }}>
                On / Off
              </p>
            </Button>
          </Col>
          <Col xs={7}>
            <Row>
              <Col style={{padding: '0em 0 0.5em'}}>
                <FaCircle size={25} color={curStyle}/>
                <p style={{
                  display: 'inline-table',
                  margin: 'auto',
                  paddingLeft: '0.8em',
                  fontSize: '18px',
                  textAlign: 'right'
                }}>
                  {text}
                </p>
                {/*<p style={{textAlign: 'center', margin: 'auto', fontSize: '18px'}}>{text}</p>*/}
              </Col>
            </Row>
            <Row style={{padding: '0'}}>
              <Col style={{padding: '0 0.2em'}}>
                <div className='round-icon'>
                  <FaWalking size={35} color={walkingState > 0 ? 'green' : 'gray'}/>
                </div>
              </Col>
              <Col style={{padding: '0 0.2em'}}>
                <div className='round-icon'>
                  <BiTrain size={35} color={trainWayState > 0 ? 'green' : 'gray'}/>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  onChangeActiveTime(isBegin, time) {
    this.isEditTime = true;
    if (isBegin) {
      this.setState(state => state.activeTimeBegin = time);
    } else {
      this.setState(state => state.activeTimeEnd = time);
    }
  }

  onSubmitActiveTime() {
    let begin = this.state.activeTimeBegin;
    let end = this.state.activeTimeEnd;
    try {
      let beginHour = begin.split(':')[0];
      let beginMinute = begin.split(':')[1];
      let endHour = end.split(':')[0];
      let endMinute = end.split(':')[1];
      if (this.onSetActiveTime) {
        this.onSetActiveTime(beginHour, beginMinute, endHour, endMinute);
      }
    } catch (e) {
      console.log(e);
    }
  }

  controlItem(control, begin, end) {
    return (
      <div style={{minHeight: '5.1em', margin: 'auto'}}>
        <Row style={{padding: '0'}}>
          <Col>
            <Row style={{padding: '0.5em 0', margin: '0px'}}>
              <Col xs={3} style={{margin: 'auto', padding: '0'}}>
                <p style={{textAlign: 'right', margin: 'auto', fontSize: '18px'}}>Thời gian hoạt động:</p>
              </Col>
              <Col xs={3} style={{padding: '0'}}>
                Từ: <input
                className='monitor-input-time-active'
                style={{width:'7em'}}
                type="time"
                id="fname"
                name="fname"
                value={this.state.activeTimeBegin}
                disabled={this.props.role !== 1 && this.props.role !== 2}
                onChange={(event) => this.onChangeActiveTime(true, event.target.value)}
              />
              </Col>
              <Col xs={1}><BiRightArrowAlt size={25} /></Col>
              <Col xs={3} style={{padding: '0'}}>
                Đến: <input
                className='monitor-input-time-active'
                style={{width: '7em'}}
                type="time"
                id="fname"
                name="fname"
                value={this.state.activeTimeEnd}
                disabled={this.props.role !== 1 && this.props.role !== 2}
                onChange={(event) => this.onChangeActiveTime(false, event.target.value)}
              />
              </Col>
              <Col xs={2} style={{padding: '0'}}>
                <Button
                  variant='info'
                  onClick={() => this.onSubmitActiveTime()}
                  disabled={this.props.role !== 1 && this.props.role !== 2}
                >
                  Thiết lập
                </Button>
              </Col>
            </Row>
            <Row style={{padding: '0.5em 0', margin: '0px'}}>
              <Col xs={3} style={{margin: 'auto', padding: '0'}}>
                <p style={{textAlign: 'right', margin: 'auto', fontSize: '18px'}}>Điều khiển tuyến:</p>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 1 ? 'info' : 'secondary'}
                  onClick={() => this.onControlSubmit(true, 1)}
                  disabled={(this.props.value.stationInfo.numPhase < 1) || (this.props.role !== 1 && this.props.role !== 2)}
                >
                  T1
                </Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 2 ? 'info' : 'secondary'}
                  onClick={() => this.onControlSubmit(true, 2)}
                  disabled={(this.props.value.stationInfo.numPhase < 2) || (this.props.role !== 1 && this.props.role !== 2)}
                >
                  T2
                </Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 3 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 3) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 3)}>T3</Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 4 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 4) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 4)}>T4</Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 5 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 5) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 5)}>T5</Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 6 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 6) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 6)}>T6</Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 7 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 7) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 7)}>T7</Button>
              </Col>
              <Col xs={1} style={{margin: 'auto', padding: '0px'}}>
                <Button
                  variant={control?.lane === 8 ? 'info' : 'secondary'}
                  disabled={(this.props.value.stationInfo.numPhase < 8) || (this.props.role !== 1 && this.props.role !== 2)}
                  onClick={() => this.onControlSubmit(true, 8)}>T8</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  getSign(data, index, signType, isLand, railwayFlag, i) {
    let ret = null;
    if (data == null) {
      return ret;
    }
    data.forEach(item => {
      if (item?.index === index) {
        switch (signType) {
          case SIGN_TYPE.TRAFFIC_LIGHT:
            if (railwayFlag === 1 && this.props.value.trainWayMode > 0) {
              ret = isLand ? lightLandRed : lightRed;
              ret = (i === 0 && this.props.value.stationInfo.numPhase > 2) ? lightLeftRed : ret;
            } else if (item?.trafficLight === 'green') {
              ret = isLand ? lightLandGreen : lightGreen;
              ret = (i === 0 && this.props.value.stationInfo.numPhase > 2) ? lightLeftGreen : ret;
            } else if (item?.trafficLight === 'red') {
              ret = isLand ? lightLandRed : lightRed;
              ret = (i === 0 && this.props.value.stationInfo.numPhase > 2) ? lightLeftRed : ret;
            } else if (item?.trafficLight === 'yellow') {
              ret = isLand ? lightLandYellow : lightYellow;
              ret = (i === 0 && this.props.value.stationInfo.numPhase > 2) ? lightLeftYellow : ret;
            } else {
              ret = isLand ? lightLandNone : lightNone;
              ret = (i === 0 && this.props.value.stationInfo.numPhase > 2) ? lightLeftNone : ret;
            }
            break;
          case SIGN_TYPE.WALKING:
            if (item?.walking === 'green') {
              ret = walkingGreen;
            } else if (item?.walking === 'red') {
              ret = walkingRed
            } else {
              ret = walkingOff;
            }
            break;
          case SIGN_TYPE.RAILWAY:
            if (this.props.value.trainWayMode > 0 && railwayFlag === 1) {
              ret = railwayOn;
            } else {
              ret = railwayOff;
            }
        }
      }
    })
    return ret;
  }

  getCounterColor(data, index) {
    let ret = colorRED;
    if (data === null) {
      return ret;
    }
    data.forEach(item => {
      if (item?.index === index) {
        if (item.trafficLight === "red") {
          ret = colorRED;
        } else if (item.trafficLight === "green") {
          ret = colorGREEN;
        } else if (item.trafficLight === "yellow") {
          ret = colorYELLOW;
        } else {
          ret = "none";
        }
      }
    })
    return ret;
  }

  getCounterValue(data, index, railwayFlag) {
    let ret = 255;
    if (data === null) {
      return ret;
    }
    data.forEach(item => {
      if (item?.index === index) {
        ret = item.counter;
        if (railwayFlag === 1 && this.props.value.trainWayMode > 0) {
          ret = 255;
        }
      }
    })
    return ret === 255 ? '' : ret.pad(2);
  }

  onDrag(index, type, x, y) {
    console.log("onDrag[%d] type: %s, x: %d, y: %d", index, type, x, y)
    switch (type) {
      case SIGN_TYPE.TRAFFIC_LIGHT:
        this.setState(state => {
          state.signSetting[index].trafficLight.x = x;
          state.signSetting[index].trafficLight.y = y;
          return state;
        });
        break;
      // case SIGN_TYPE.TRAFFIC_LIGHT_LEFT:
      //   this.setState(state => {
      //     state.signSetting[index].trafficLightLeft.x = x;
      //     state.signSetting[index].trafficLightLeft.y = y;
      //     return state;
      //   });
      //   break;
      case SIGN_TYPE.COUNTDOWN:
        this.setState(state => {
          state.signSetting[index].countdown.x = x;
          state.signSetting[index].countdown.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.WALKING:
        this.setState(state => {
          state.signSetting[index].walking.x = x;
          state.signSetting[index].walking.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.RAILWAY:
        this.setState(state => {
          state.signSetting[index].railway.x = x;
          state.signSetting[index].railway.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.TURN_RIGHT:
        this.setState(state => {
          state.signSetting[index].turnRight.x = x;
          state.signSetting[index].turnRight.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.TURN_LEFT:
        this.setState(state => {
          state.signSetting[index].turnLeft.x = x;
          state.signSetting[index].turnLeft.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.GO_AHEAD:
        this.setState(state => {
          state.signSetting[index].goAhead.x = x;
          state.signSetting[index].goAhead.y = y;
          return state;
        });
        break;
      case SIGN_TYPE.LABEL:
        this.setState(state => {
          state.signSetting[index].label.x = x;
          state.signSetting[index].label.y = y;
          return state;
        });
        break;

    }
  }

  viewItem(data) {
    let laneList = [];
    this.state.signSetting.forEach((setting, index) => {
      if (index > 3) {
        return;
      }
      let isLand = index % 2 === 0;
      laneList.push(
        <div>
          <DraggableSign
            x={setting.label.x}
            y={setting.label.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.LABEL, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <div className='station-label-sign'>
              {setting.label.text}
            </div>
          </DraggableSign>
          {setting.trafficLight.channel !== 0 &&
          <div>
            <DraggableSign
              x={setting.trafficLight.x}
              y={setting.trafficLight.y}
              onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.TRAFFIC_LIGHT, x, y)}
              isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
            >
              <img style={isLand ? {height: '3.6em'} : {width: '3.6em'}} src={
                this.getSign(data, setting.trafficLight.channel, SIGN_TYPE.TRAFFIC_LIGHT, isLand, setting.railway.channel, index)
              }/>
            </DraggableSign>
            <DraggableSign
              x={setting.countdown.x}
              y={setting.countdown.y}
              onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.COUNTDOWN, x, y)}
              isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
            >
              <img style={{height: '3.6em'}} src={counterLight}/>
              <div className='tag-text-common' style={{color: this.getCounterColor(data, setting.trafficLight.channel)}}>
                {this.getCounterValue(data, setting.trafficLight.channel, setting.railway.channel)}
              </div>
            </DraggableSign>
          </div>
          }
          {setting.walking.channel !== 0 &&
          <DraggableSign
            x={setting.walking.x}
            y={setting.walking.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.WALKING, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <img style={{width: '3.6em'}} src={
              this.getSign(data, setting.walking.channel, SIGN_TYPE.WALKING, isLand, false, index)
            }/>
          </DraggableSign>
          }
          {setting.railway.channel !== 0 &&
          <DraggableSign
            x={setting.railway.x}
            y={setting.railway.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.RAILWAY, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <img style={{width: '3.6em'}} src={
              this.getSign(data, setting.railway.channel, SIGN_TYPE.RAILWAY, isLand, setting.railway.channel, index)
            }/>
          </DraggableSign>
          }
          {setting.turnLeft.channel !== 0 &&
          <DraggableSign
            x={setting.turnLeft.x}
            y={setting.turnLeft.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.TURN_LEFT, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <img style={{width: '3.6em'}} src={turnLeftOff}/>
          </DraggableSign>
          }
          {setting.turnRight.channel !== 0 &&
          <DraggableSign
            x={setting.turnRight.x}
            y={setting.turnRight.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.TURN_RIGHT, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <img style={{width: '3.6em'}} src={turnRightOff}/>
          </DraggableSign>
          }
          {setting.goAhead.channel !== 0 &&
          <DraggableSign
            x={setting.goAhead.x}
            y={setting.goAhead.y}
            onDrag={(x, y) => this.onDrag(index, SIGN_TYPE.GO_AHEAD, x, y)}
            isDisabled={this.state.mode !== ACTION_MODE.STATION_CONFIG}
          >
            <img style={{width: '3.6em'}} src={goAheadOff}/>
          </DraggableSign>
          }
        </div>
      )
    })
    return (
      <div>
        {laneList}
        <img style={{width: '100%', maxHeight: '45em', display: 'block', borderRadius: '10px'}}
             src={this.state.backgroundSrc}/>
      </div>
    )
  }

  // 'https://i.ibb.co/XCqkLPs/city-top-view.jpg'

  messageItem(message) {
    return (
      <div>
        <Row style={{padding: '0.2em', paddingRight: '1em'}}>
          <Col xs={2}>
            <p style={{textAlign: 'right', marginTop: '0.5em', fontSize: '18px'}}>
              Tin nhắn:
            </p>
          </Col>
          <Col xs={10}>
            <div className='message-box'>
              <p className='message-text'>
                {(new Date()).toLocaleString() + ": " + message}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  monitorScheduleView(timeSetting) {
    return(
      <div>
        <Row style={{marginTop: '0.5em', marginBottom: '1em'}}>
          <Col xs={4} className='monitor-table-left'>Tuyến</Col>
          <Col style={{fontWeight: 'bold', maxWidth: '7em'}}>1</Col>
          <Col style={{fontWeight: 'bold', maxWidth: '7em'}}>2</Col>
          {this.props.value.stationInfo.numPhase > 2 && <Col style={{fontWeight: 'bold', maxWidth: '7em'}}>3</Col>}
          {/*<Col xs={2} style={{fontWeight: 'bold', maxWidth: '3.5em'}}>4</Col>*/}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={4} className='monitor-table-left'>
            <FaCircle size={25} color={'rgba(104, 214, 36, 0.79)'}/>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Xanh
            </p>
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[0].greenTime > 0 ? timeSetting[0].greenTime : '--'} />
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[1].greenTime > 0 ? timeSetting[1].greenTime : '--'} />
          </Col>
          {this.props.value.stationInfo.numPhase > 2 && timeSetting[2] !== undefined && <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[2].greenTime > 0 ? timeSetting[2].greenTime : '--'} />
          </Col>}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[2].greenTime} />*/}
          {/*</Col>*/}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[3].greenTime} />*/}
          {/*</Col>*/}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={4} className='monitor-table-left'>
            <FaCircle size={25} color={'#D1D84B'}/>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Vàng
            </p>
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[0].yellowTime > 0 ? timeSetting[0].yellowTime : '--'} />
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[1].yellowTime > 0 ? timeSetting[1].yellowTime : '--'} />
          </Col>
          {this.props.value.stationInfo.numPhase > 2 && timeSetting[2] !== undefined && <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[2].yellowTime > 0 ? timeSetting[2].yellowTime : '--'} />
          </Col>}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[2].yellowTime} />*/}
          {/*</Col>*/}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[3].yellowTime} />*/}
          {/*</Col>*/}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={4} className='monitor-table-left'>
            <FaCircle size={25} color={'#D64D4B'}/>
            <p style={{display: 'inline-table', marginLeft: '1.52em'}}>
              Đỏ
            </p>
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[0].redTime > 0 ? timeSetting[0].redTime : '--'} />
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[1].redTime > 0 ? timeSetting[1].redTime : '--'} />
          </Col>
          {this.props.value.stationInfo.numPhase > 2 && timeSetting[2] !== undefined && <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[2].redTime > 0 ? timeSetting[2].redTime : '--'} />
          </Col>}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[2].redTime} />*/}
          {/*</Col>*/}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[3].redTime} />*/}
          {/*</Col>*/}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={4} className='monitor-table-left'>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Giải tỏa
            </p>
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[0].clearanceTime > 0 ? timeSetting[0].clearanceTime : '--'} />
          </Col>
          <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[1].clearanceTime > 0 ? timeSetting[1].clearanceTime : '--'} />
          </Col>
          {this.props.value.stationInfo.numPhase > 2 && timeSetting[2] !== undefined && <Col style={{maxWidth: '7em'}}>
            <input
              className='monitor-input-time'
              type="text"
              id="fname"
              name="fname"
              disabled={true}
              value={timeSetting[2].clearanceTime > 0 ? timeSetting[2].clearanceTime : '--'} />
          </Col>}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[2].clearanceTime} />*/}
          {/*</Col>*/}
          {/*<Col style={{maxWidth: '7em'}}>*/}
          {/*  <input className='monitor-input-time' type="text" id="fname" name="fname" disabled={true} value={timeSetting[3].clearanceTime} />*/}
          {/*</Col>*/}
        </Row>
      </div>
    )
  }

  onScheduleSettingChange(type, index, value) {
    if (value > 255 || value < 0) {
      window.api.send('asynchronous-message', 'dialog', null, null, "Thời gian thiết lập không hợp lệ");
      return;
    }
    switch (type) {
      case 'green':
        if (index >= this.state.scheduleChangeModel.green.length) {
          return;
        }
        this.setState(state => state.scheduleChangeModel.green[index] = value);
        break;
      case 'yellow':
        this.setState(state => state.scheduleChangeModel.yellow = value);
        break;
      case 'clearance':
        this.setState(state => state.scheduleChangeModel.clearance = value);
        break;
    }
  }

  monitorScheduleChangeView(timeSetting) {
    return(
      <div>
        <Row style={{marginTop: '0.5em', marginBottom: '1em'}}>
          <Col xs={this.props.value.stationInfo.numPhase > 2 ? 3 : 4} className='monitor-table-left'>Pha</Col>
          <Col xs={3} style={{fontWeight: 'bold', maxWidth: '5.5em', textAlign: 'center', padding: 0}}>1</Col>
          <Col xs={3} style={{fontWeight: 'bold', maxWidth: '5.5em', textAlign: 'center', padding: 0}}>2</Col>
          {this.props.value.stationInfo.numPhase > 2 && <Col xs={3} style={{fontWeight: 'bold', maxWidth: '5.5em', textAlign: 'center', padding: 0}}>3</Col>}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={this.props.value.stationInfo.numPhase > 2 ? 3 : 4} className='monitor-table-left'>
            <FaCircle size={25} color={'rgba(104, 214, 36, 0.79)'}/>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Xanh
            </p>
          </Col>
          <Col xs={3} style={{maxWidth: '5.5em'}}>
            <input
              className='monitor-input-time'
              type="number"
              id="fname"
              name="fname"
              value={this.state.scheduleChangeModel.green[0]}
              min={5}
              max={255}
              onChange={(event) => {this.onScheduleSettingChange('green', 0, event.target.value)}}
            />
          </Col>
          <Col xs={3} style={{maxWidth: '5.5em'}}>
            <input
              className='monitor-input-time'
              type="number"
              id="fname"
              name="fname"
              min={5}
              max={255}
              value={this.state.scheduleChangeModel.green[1]}
              onChange={(event) => {this.onScheduleSettingChange('green', 1, event.target.value)}}
            />
          </Col>
          {this.props.value.stationInfo.numPhase > 2 && <Col xs={3} style={{maxWidth: '5.5em'}}>
            <input
              className='monitor-input-time'
              type="number"
              id="fname"
              name="fname"
              min={5}
              max={255}
              value={this.state.scheduleChangeModel.green[2]}
              onChange={(event) => {this.onScheduleSettingChange('green', 2, event.target.value)}}
            />
          </Col>}
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={this.props.value.stationInfo.numPhase > 2 ? 3 : 4} className='monitor-table-left'>
            <FaCircle size={25} color={'#D1D84B'}/>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Vàng
            </p>
          </Col>
          <Col xs={6} style={{maxWidth: '11em'}}>
            <input
              className='monitor-input-time'
              type="number"
              id="fname"
              name="fname"
              min={0}
              max={255}
              value={this.state.scheduleChangeModel.yellow}
              onChange={(event) => {this.onScheduleSettingChange('yellow', 0, event.target.value)}}
            />
          </Col>
        </Row>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={this.props.value.stationInfo.numPhase > 2 ? 3 : 4} className='monitor-table-left'>
            <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
              Giải tỏa
            </p>
          </Col>
          <Col xs={6} style={{maxWidth: '11em'}}>
            <input
              className='monitor-input-time'
              type="number"
              id="fname"
              name="fname"
              min={0}
              max={255}
              value={this.state.scheduleChangeModel.clearance}
              onChange={(event) => {this.onScheduleSettingChange('clearance', 1, event.target.value)}}
            />
          </Col>
        </Row>
      </div>
    )
  }

  onClickSyncTime() {
    if (this.onSyncTime) {
      this.onSyncTime();
    }
  }

  outputSensorList(stationInfo, outputSensor) {
    let size = stationInfo !== undefined && stationInfo.nCard !== undefined ? stationInfo.nCard : 2;
    let list = [];
    let greenSignal = 'rgba(104, 214, 36, 0.79)';
    let redSignal = 'rgba(214,36,57,0.79)';
    let scanRed = false;
    for (let i = 0; i < size; i++) {
      let ballList = [];
      let val = outputSensor !== undefined && outputSensor.length >= (i - 1) ? outputSensor[i] : 0;
      for (let j = 0; j < 8; j++) {
        let isRed = (val & (0x01 << j)) !== 0;
        if (isRed === true) {
          scanRed = true;
        }
        ballList.push(
          <Col xs={1} style={{padding: '0'}}><FaCircle size={20} color={isRed ? redSignal : greenSignal}/></Col>
        )
      }
      list.push(
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={3} className='monitor-table-left'>
            Card {i + 1}
          </Col>
          {ballList}
        </Row>
      );
    }
    if (scanRed !== this.state.isErrorSignal) {
      this.setState(state => state.isErrorSignal = scanRed);
    }
    return (
      <Container>
        <Row style={{marginTop: '0.5em'}}>
          <Col xs={3} className='monitor-table-left'>Kênh</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>1</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>2</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>3</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>4</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>5</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>6</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>7</Col>
          <Col xs={1} style={{fontWeight: 'bold', textAlign: 'center', padding: '0'}}>8</Col>
        </Row>
        {list}
      </Container>
    )
  }

  onSubmitChangeNumberCard() {
    if (this.changeNumberCard !== null) {
      this.changeNumberCard(this.props.value.stationId, this.state.nCard);
    }
  }

  setRadioValue(value) {
    /*this.setState(state => {
      state.radioValue = value;
      return state;
    })*/
    if (this.updateErrorStatus !== null) {
      this.updateErrorStatus(this.props.value.stationId, value)
    }
  }

  renderErrorStatus(errorStatus, noteList) {
    console.log("error status %d: %s", errorStatus, noteList)
    if (noteList.length === 0 || errorStatus === 0) {
      return (<div />);
    }
    let noteShow = [];
    noteList.forEach(note => {
      noteShow.push(
        <p>{note}</p>
      )
    })
    return(
      <Container>
        <ButtonGroup>
          {this.radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx === 0 ? 'outline-danger' : (idx === 1 ? 'outline-primary' : 'outline-success')}
              name="radio"
              value={radio.value}
              checked={errorStatus === radio.value}
              onChange={(e) => this.setRadioValue(e.currentTarget.value)}
              disabled={idx === 2 && this.state.isErrorSignal === true}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <Container className='error-status-box'>
          {noteShow}
        </Container>
        {(errorStatus === 3) &&
          <Container style={{paddingTop: '0.5em'}}>
            <Button variant={'danger'} onClick={() => this.setRadioValue(0)}>
              Clear
            </Button>
          </Container>
        }
      </Container>
    )
  }

  monitorItem(stationInfo, timeSetting, outputSignal, errorStatus, noteList, data) {
    let signSignal = [];
    this.state.signSetting.map((sign, i) => {
      signSignal.push(
        <Col className='signal-item'>
          <Row style={{height: '2em', overflowY: 'scroll'}}>
            <Col style={{fontSize: '12px'}}>{sign.label.text}</Col>
          </Row>
          <Row style={{paddingTop: '1em'}}>
            <Col>
              <img style={{width: '3em', verticalAlign: 'bottom'}} src={
                this.getSign(data, sign.trafficLight.channel, SIGN_TYPE.TRAFFIC_LIGHT, false, sign.railway.channel)
              }/>
            </Col>
          </Row>
        </Col>
      )
    })
    return(
      <div style={{margin: 'auto'}}>
        <Container className='monitor-station-title'>
          <p className='monitor-station-title-text'>
            {stationInfo?.stationName}
          </p>
        </Container>
        <Container className='component-mobile monitor-card'>
          {this.statusItem(
            this.props.value.isOnline,
            this.props.value.walkingMode,
            this.props.value.trainWayMode,
            this.props.value.control.onOff,
            true
          )}
        </Container>
        <Container className='monitor-card'>
          <BiChip size={30}/>
          <p className='monitor-card-title'>
            Thông tin CPU
          </p>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Mã thiết bị :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {stationInfo.hardwareId}
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Kiểu CPU :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {stationInfo.type}
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Số pha :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {stationInfo.numPhase}
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Thời gian CPU :
            </Col>
            <Col xs={3} className='monitor-table-right' xs={4}>
              {stationInfo.cpuTime}
            </Col>
            <Col xs={3} style={{padding: '0'}} xs={3}>
              <Button
                variant='info'
                size={'sm'}
                className='btn-sync'
                onClick={() => this.onClickSyncTime()}
                disabled={this.props.role !== 1 && this.props.role !== 2}
              >
                Đồng bộ
              </Button>
            </Col>
          </Row>
          {/*<Row style={{marginTop: '0.5em'}}>*/}
          {/*</Row>*/}
        </Container>
        <Container className='component-mobile monitor-card'>
          <Row style={{padding: '0.5em 0', margin: '0px'}}>
            <Col style={{margin: 'auto', padding: '0'}}>
              <p style={{margin: 'auto', fontSize: '18px'}}>Thời gian hoạt động:</p>
            </Col>
          </Row>
          <Row>
            <Col>
              Từ: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeBegin}
              disabled={true}
            />
            </Col>
            <Col xs={1}><BiRightArrowAlt size={25} /></Col>
            <Col>
              Đến: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeEnd}
              disabled={true}
            />
            </Col>
          </Row>
        </Container>
        <Container className='monitor-card mobile-sign-signal'>
          <FaTrafficLight size={25} />
          <p className='monitor-card-title'>
            Đèn tín hiệu
          </p>
          <Row style={{padding: '1em'}}>
            {signSignal}
          </Row>
        </Container>
        <Container className='monitor-card'>
          <BiTimer size={30} />
          <p className='monitor-card-title'>
            Thời gian đếm ngược
          </p>
          {this.state.isChangeScheduleMode ?
            this.monitorScheduleChangeView(timeSetting) : this.monitorScheduleView(timeSetting)
          }
          {this.state.isChangeScheduleMode === false ?
            <Row style={{marginTop: '0.5em'}}>
              <Col>
                <Button
                  variant='info'
                  onClick={() => this.onClickChangeSchedule('change')}
                  disabled={this.props.role !== 1 && this.props.role !== 2}
                  className='btn-change'
                >
                  Thay đổi
                </Button>
              </Col>
            </Row>
            :
            <Row style={{marginTop: '0.5em'}}>
              <Col style={{float: 'right'}}>
                <Button style={{float: 'right', width: '6em'}} variant='danger' onClick={() => this.onClickChangeSchedule('cancel')}>
                  Hủy
                </Button>
              </Col>
              <Col style={{float: 'left'}}>
                <Button style={{float: 'left', width: '6em'}} variant='primary' onClick={() => this.onClickChangeSchedule('submit')}>
                  Cập nhật
                </Button>
              </Col>
            </Row>
            }
        </Container>
        <Container className='monitor-card'>
          <AiOutlineControl size={30} />
          <p className='monitor-card-title'>
            Trạng thái lỗi ngõ ra
          </p>
          {this.state.mode === ACTION_MODE.STATION_CONFIG &&
            <Container>
              <Row>
                <Col xs={5} style={{
                  float: 'right',
                  textAlign: 'right'
                }}
                     className='light-setting-label'
                >
                  Số Card:
                </Col>
                <Col xs={2}>
                  <input
                    type="number"
                    min={2}
                    max={8}
                    style={{
                      float: 'left',
                      alignContent: 'left',
                      minWidth: '3em'
                    }}
                    value={this.state.nCard}
                    onChange={(event) => this.onChangeNumberCard(event.target.value)}/>
                </Col>
                <Col xs={5}>
                  <Button
                    variant='info'
                    style={{
                      float: 'left',
                      padding: '0.2em 0.5em',
                    }}
                    onClick={() => this.onSubmitChangeNumberCard()}
                  >
                    Lưu
                  </Button>
                </Col>
              </Row>
            </Container>
          }
          {this.renderErrorStatus(errorStatus, noteList)}
          {this.outputSensorList(stationInfo, outputSignal)}
        </Container>
        <Container className='btn-mobile'>
          <Button variant='danger' style={{width: '6em'}} onClick={() => this.onClickClose()}>
            <Row style={{margin: '0', padding: '0'}}>
              {/*<Col style={{margin: '0', padding: '0'}} xs={2}>*/}
              {/*  <RiCloseCircleLine size={20}/>*/}
              {/*</Col>*/}
              <Col style={{margin: '0', padding: '0', textAlign: 'center'}}>
                Đóng
              </Col>
            </Row>
          </Button>
        </Container>
      </div>
    )
  }

  onChangeActionMode(mode) {
    switch (mode) {
      case ACTION_MODE.NONE:
        window.api.send('asynchronous-message', 'dialog', null, null, "Tính năng chưa được hỗ trợ !");
        break;
      case ACTION_MODE.MONITOR:
        this.setState(state => state.mode = mode);
        break;
      case ACTION_MODE.STATION_CONFIG:
        if (this.props.role !== 1) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Tính năng chỉ dành cho admin !");
          } else {
            alert("Tính năng chỉ dành cho admin !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        break;
      case ACTION_MODE.DIARY:
        if (this.props.role !== 1 && this.props.role !== 2) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối !");
          } else {
            alert("Truy cập bị từ chối !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        this.onSubmitDiary(this.props.value.stationId, '');
        break;
      case ACTION_MODE.SCHEDULE:
        if (this.props.role !== 1 && this.props.role !== 2) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối !");
          } else {
            alert("Truy cập bị từ chối !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        this.onGetSchedule(this.props.value.stationId, '');
        break;
    }
  }

  navBarView() {
    return(
      <div>
        <Container className='user'>
          <Row>
            <Col xs={2}>
              <img src={avatar} className='user-icon' />
            </Col>
            <Col xs={7} style={{margin: 'auto'}}>
              <p className='user-name'>
                {this.user}
              </p>
            </Col>
          </Row>
        </Container>
        <Container style={{margin: '0', padding: '0'}}>
          <ListGroup style={{padding: '1.5em 0em'}} defaultActiveKey='#1'>
            <ListGroup.Item className='nav-item' action href='#1' onClick={() => this.onChangeActionMode(ACTION_MODE.MONITOR)}>
              <Row>
                <Col xs={3}>
                  <RiLayoutMasonryFill size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Giám sát
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#2' onClick={() => this.onChangeActionMode(ACTION_MODE.STATION_CONFIG)}>
              <Row>
                <Col xs={3}>
                  <AiTwotoneSetting size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Thiết lập
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#3' onClick={() => this.onChangeActionMode(ACTION_MODE.SCHEDULE)}>
              <Row>
                <Col xs={3}>
                  <AiOutlineSchedule size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Lập lịch
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#4' onClick={() => this.onChangeActionMode(ACTION_MODE.DIARY)}>
              <Row>
                <Col xs={3}>
                  <AiOutlineFileExclamation size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}} >
                  Báo cáo
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Container>
        <Container style={{bottom: '2em', position: 'absolute'}}>
          <Button variant='danger' style={{width: '6em'}} onClick={() => this.onClickClose()}>
            <Row style={{margin: '0', padding: '0'}}>
              {/*<Col style={{margin: '0', padding: '0'}} xs={2}>*/}
              {/*  <RiCloseCircleLine size={20}/>*/}
              {/*</Col>*/}
              <Col style={{margin: '0', padding: '0', textAlign: 'center'}}>
                Đóng
              </Col>
            </Row>
          </Button>
        </Container>
      </div>
    )
  }

  onChangeSettingChannel(index, type, value) {
    console.log("onChangeSettingChannel " + index + " " + type + " " + value);
    if (index < 0 || index >= this.state.signSetting.length) {
      return;
    }
    this.setState(state => {
      switch (type) {
        case SIGN_TYPE.COUNTDOWN:
          state.signSetting[index].countdown.channel = value;
          break;
        case SIGN_TYPE.TRAFFIC_LIGHT:
          state.signSetting[index].trafficLight.channel = value;
          break;
        // case SIGN_TYPE.TRAFFIC_LIGHT_LEFT:
        //   state.signSetting[index].trafficLightLeft.channel = value;
        //   break;
        case SIGN_TYPE.WALKING:
          state.signSetting[index].walking.channel = value;
          break;
        case SIGN_TYPE.TURN_LEFT:
          state.signSetting[index].turnLeft.channel = value;
          break;
        case SIGN_TYPE.GO_AHEAD:
          state.signSetting[index].goAhead.channel = value;
          break;
        case SIGN_TYPE.TURN_RIGHT:
          state.signSetting[index].turnRight.channel = value;
          break;
        case SIGN_TYPE.RAILWAY:
          state.signSetting[index].railway.channel = value;
          break;
        case SIGN_TYPE.LABEL:
          state.signSetting[index].label.text = value;
          break;
      }
      return state;
    })
  }

  signalSettingView() {
    const model = this.state.signSetting;
    let laneList = [];
    let count = 0;
    model.forEach((item, index) => {
      if (index > 3) {
        return;
      }
      laneList.push(
        <Row className='light-setting-lane-component'>
          <Col>
            {"Trụ " + (index + 1)}
          </Col>
          <Col>
            <input
              type="text"
              style={{width: '6em'}}
              disabled={this.state.signSetting[index].trafficLight.channel === 0}
              value={item.label.text}
              onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.LABEL, event.target.value))}
            />
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.trafficLight.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].trafficLight.channel === 0}
                  value={this.state.signSetting[index].trafficLight.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          {/*<Col>*/}
          {/*  <Row>*/}
          {/*    <Col style={{padding: '0.5em'}} xs={4}>*/}
          {/*      <input*/}
          {/*        type="checkbox"*/}
          {/*        checked={item.trafficLightLeft.channel !== 0}*/}
          {/*        style={{width: '15px', height: '15px', float: 'right'}}*/}
          {/*        onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT_LEFT, event.target.checked ? 1 : 0)}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*    <Col style={{padding: '0em'}} xs={8}>*/}
          {/*      <input*/}
          {/*        type="number"*/}
          {/*        max={8}*/}
          {/*        min={1}*/}
          {/*        disabled={this.state.signSetting[index].trafficLightLeft.channel === 0}*/}
          {/*        value={this.state.signSetting[index].trafficLightLeft.channel}*/}
          {/*        onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT_LEFT, parseInt(event.target.value)))}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.walking.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.WALKING, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].walking.channel === 0}
                  value={this.state.signSetting[index].walking.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.WALKING, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.turnLeft.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_LEFT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].turnLeft.channel === 0}
                  value={this.state.signSetting[index].turnLeft.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_LEFT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.goAhead.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.GO_AHEAD, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].goAhead.channel === 0}
                  value={this.state.signSetting[index].goAhead.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.GO_AHEAD, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.turnRight.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_RIGHT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].turnRight.channel === 0}
                  value={this.state.signSetting[index].turnRight.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_RIGHT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}}>
                <input
                  type="checkbox"
                  checked={item.railway.channel !== 0}
                  style={{width: '15px', height: '15px'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.RAILWAY, event.target.checked ? 1 : 0)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )
      count++;
    })
    return (
      <Container className='light-setting-label'>
        <Row className='light-setting-lane-component'>
          <Col />
          <Col>
            Nhãn
          </Col>
          <Col>
            Đèn giao thông
          </Col>
          {/*<Col>*/}
          {/*  Đèn giao thông rẽ trái*/}
          {/*</Col>*/}
          <Col>
            Đèn đi bộ
          </Col>
          <Col>
            Đèn rẽ trái
          </Col>
          <Col>
            Đèn đi thẳng
          </Col>
          <Col>
            Đèn rẽ phải
          </Col>
          <Col>
            Tín hiệu đường sắt
          </Col>
        </Row>
        {laneList}
      </Container>
    )
  }

  onSubmitSignSetting() {
    if (this.state.file == null) {
      if (this.submitSignSetting != null) {
        this.submitSignSetting(this.props.value.stationId, this.state.signSetting, null)
      }
      return;
    }
    const formData = new FormData();

    formData.append('image', this.state.file);

    fetch(
      'https://api.imgbb.com/1/upload?key=d5b08431cefce2d6bf84b339c9ca9aa3',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        if (result.status === 200) {
          this.setState(state => state.backgroundSrc = result?.data?.image?.url)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        if (this.submitSignSetting != null) {
          this.submitSignSetting(this.props.value.stationId, this.state.signSetting, this.state.backgroundSrc)
        }
      })
    ;
  }

  onFileChanged(file) {
    this.setState(state => {
      state.file = file;
      // state.backgroundSrc = file;
      return state;
    })
  }

  onChangeNumberCard(nCard) {
    this.setState(state => {
      state.nCard = nCard;
      return state;
    });
  }

  onSubmitDiary(stationId, message) {
    if (this.submitDiary !== null) {
      this.submitDiary(stationId, message);
    }
  }

  onSubmitAccessory() {
    if (this.addAccessory !== null) {
      this.addAccessory(
        this.props.value.stationId,
        this.state.accessoryForm.name,
        this.state.accessoryForm.model,
        this.state.accessoryForm.madeBy,
        this.state.accessoryForm.position,
        this.state.accessoryForm.installedPosition,
        this.state.accessoryForm.installedDate,
        this.state.accessoryForm.state
      )
    }
  }

  onDeleteAccessory(accessoryId) {
    if (this.deleteAccessory !== null) {
      this.deleteAccessory(this.props.value.stationId, accessoryId);
    }
  }

  downloadReport() {
    fetch(
      config.HOST_HTTP + "/api/v1/user/report?stationId=" + this.props.value.stationId,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (response.status === 200) {
          let fileName = response.headers.has('content-disposition') ? response.headers.get('content-disposition').split('filename=')[1] : 'report.xlsx';
          console.log("response " + fileName);
          response.blob().then(blob => {
            save(blob, fileName).then(() => {
              console.log("Luu hoan tat")
            });
            // window.close();
          });
        }
        // response.blob();
        // response.status;
      })
      .finally(() => {
        // this.getLatestFirmwareVersion();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  viewDevice(diaryText, accessories) {
    let stations = [];
    accessories.forEach((device, i) => {
      console.log("date " + device.getInstalleddate())
      stations.push(
        <Row className='item-row'>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{i + 1}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getName()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getModel()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getMadeby()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getPosition()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getInstalledpos()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getInstalleddate()}</Col>
          <Col style={{fontSize: '14px', display: 'flex', justifyContent: 'center'}}>{device.getStatus()}</Col>
          <Col>
            <Button
              variant={'danger'}
              onClick={() => this.onDeleteAccessory(device.getId())}
            >
              Xóa
            </Button>
          </Col>
        </Row>
      )
    })
    return(
      <div>
      <Container className='tag-card'>
        <Row style={{paddingBottom: '1em'}}>
          <Col>
            <Container className='monitor-station-title'>
              <p className='monitor-station-title-text'>
                Danh sách thiết bị
              </p>
            </Container>
          </Col>
        </Row>
        <Row style={{padding: '1em 0'}}>
          <Col>
            <Row className='item-row'>
              <Col style={{fontWeight: 'bold'}}>
                No.
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Tên
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Mã thiết bị
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Xuất xứ
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Trụ số
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Vị trí lắp đặt
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Ngày lắp đặt
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Tình trạng
              </Col>
              <Col style={{fontWeight: 'bold'}}>
                Thực thi
              </Col>
            </Row>
            {stations}
          </Col>
        </Row>
        <Row style={{padding: '1em 2em'}}>
          <Col>
            {!this.state.isAddNewDevice &&
            <Button
              onClick={() => this.setState(state => state.isAddNewDevice = true)}
              disabled={this.state.isAddNewDevice}
            >
              Thêm thiết bị
            </Button>
            }
            {this.state.isAddNewDevice &&
            <div>
              <Row>
                <Col>
                  <p className='light-setting-title'>
                    Thêm thiết bị
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Tên:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='text'
                        onChange={(event) => this.setState(state => state.accessoryForm.name = event.target.value)}
                        value={this.state.accessoryForm.name}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Mã thiết bị:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='text'
                        onChange={(event) => this.setState(state => state.accessoryForm.model = event.target.value)}
                        value={this.state.accessoryForm.model}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Xuất xứ:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='text'
                        onChange={(event) => this.setState(state => state.accessoryForm.madeBy = event.target.value)}
                        value={this.state.accessoryForm.madeBy}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Lắp tại trụ:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='text'
                        onChange={(event) => this.setState(state => state.accessoryForm.position = event.target.value)}
                        value={this.state.accessoryForm.position}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Ví trí trên trụ:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='text'
                        onChange={(event) => this.setState(state => state.accessoryForm.installedPosition = event.target.value)}
                        value={this.state.accessoryForm.installedPosition}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Ngày lắp đặt:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <input
                        type='date'
                        onChange={(event) => this.setState(state => state.accessoryForm.installedDate = event.target.value)}
                        value={this.state.accessoryForm.installedDate}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col/>
                <Col>
                  <Row>
                    <Col xs={5} className='form-left'>
                      Tình trạng:
                    </Col>
                    <Col xs={7} className='form-right'>
                      <select
                        onChange={(event) => this.setState(state => state.accessoryForm.state = event.target.value)}
                        value={this.state.accessoryForm.state}
                      >
                        <option>Đang hoạt động</option>
                        <option>Dừng hoạt động</option>
                        <option>Lỗi</option>
                      </select>
                    </Col>
                  </Row>
                </Col>
                <Col/>
              </Row>
              <Row>
                <Col className='form-left'>
                  <Button
                    variant='danger'
                    onClick={() => this.setState(state => {
                      state.isAddNewDevice = false;
                      state.accessoryForm = {...DEFAULT_ACCESSORY_FORM}
                      return state;
                    })}
                  >
                    Hủy
                  </Button>
                </Col>
                <Col className='form-right'>
                  <Button
                    onClick={() => this.onSubmitAccessory()}
                  >
                    Xác nhận
                  </Button>
                </Col>
              </Row>
            </div>
            }
          </Col>
        </Row>
      </Container>
      <Container className='tag-card'>
        <Row style={{paddingBottom: '1em'}}>
          <Col>
            <Container className='monitor-station-title'>
              <p className='monitor-station-title-text'>
                Nhật ký vận hành
              </p>
            </Container>
          </Col>
        </Row>
        <Row style={{padding: '1em'}}>
          <Col>
            <Container style={{
              minHeight: '10em',
              overflow: 'scroll',
              padding: '1em',
              border: '2px solid rgba(76, 76, 76, 0.32)'
            }}>
              <p style={{
                whiteSpace: "pre-line",
                textAlign: 'left',
                fontSize: '18px',
              }}>
                {diaryText}
              </p>
            </Container>
          </Col>
        </Row>
        <Row style={{padding: '2em'}}>
          <Col style={{textAlign: 'right', paddingTop: '0.1em'}}>
            Cập nhật:
          </Col>
          <Col>
              <textarea
                rows={'2'}
                cols={'70'}
                onChange={event => this.setState(state => state.updateDiaryText = event.target.value)}
              >
                {this.state.updateDiaryText}
              </textarea>
          </Col>
          <Col style={{textAlign: 'left', paddingTop: '0.1em'}}>
            <Button
              onClick={() => this.onSubmitDiary(this.props.value.stationId, this.state.updateDiaryText)}
            >
              Lưu
            </Button>
          </Col>
        </Row>
      </Container>
        <Container style={{padding: '1em 0 2em'}}>
          <Row>
            <Col>
              <Button variant={'warning'} onClick={() => this.downloadReport()}>
                <AiOutlineDownload size={25}/> Tải báo cáo
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  render() {
    return (
      <Container fluid className='container-center' style={{margin: '0px'}}>
        <Row>
          <Col className='col-left'>
            {/*<img src={navBar} width='100%'/>*/}
            {this.navBarView()}
          </Col>
          <Col className='col-middle'>
            {this.state.mode === ACTION_MODE.MONITOR &&
              <Row>
                <Col xs={4} style={{paddingRight: '0.2em'}}>
                  <Container id='Status' className='component'>
                    {this.statusItem(
                      this.props.value.isOnline,
                      this.props.value.walkingMode,
                      this.props.value.trainWayMode,
                      this.props.value.control.onOff
                    )}
                  </Container>
                </Col>
                <Col xs={8} style={{paddingLeft: '0.5em'}}>
                  <Container className='component'>
                    {this.controlItem(this.props.value.control, this.props.value.activeTimeBegin, this.props.value.activeTimeEnd)}
                  </Container>
                </Col>
              </Row>
            }
            {(this.state.mode === ACTION_MODE.MONITOR || this.state.mode === ACTION_MODE.STATION_CONFIG) &&
              <Row>
                <Col>
                  <Container className='component'>
                    {this.viewItem(this.props.value.timer.current)}
                  </Container>
                </Col>
              </Row>
            }
            {this.state.mode === ACTION_MODE.DIARY &&
            <div>
              <Row>
                <Col>
                  {/*<Container className='component'>*/}
                    {this.viewDevice(this.props.diaryLog, this.props.value.accessories)}
                  {/*</Container>*/}
                </Col>
              </Row>
            </div>
            }
            {this.state.mode === ACTION_MODE.SCHEDULE &&
              <Schedule
                schedule={this.props.schedule}
                onUpdateSchedule={this.props.onUpdateSchedule}
                stationId={this.props.value.stationId}
              />
            }
            {(this.state.mode === ACTION_MODE.MONITOR || this.state.mode === ACTION_MODE.SCHEDULE) &&
              <Row>
                <Col>
                  <Container className='component'>
                    {this.messageItem(this.props.value.logMessage)}
                  </Container>
                </Col>
              </Row>
            }
            {this.state.mode === ACTION_MODE.STATION_CONFIG &&
              <Row>
                <Col>
                  <Container className='component' style={{padding: '0.5em 3em'}}>
                      <AiTwotoneSetting size={26}/>
                      <p className='light-setting-title'>
                        Thiết lập giao diện giám sát
                      </p>
                    {this.signalSettingView()}
                    <Container style={{padding: '1.5em 0 0 0'}}>
                      <Row>
                        <Col style={{float: 'right', textAlign: 'right'}} className='light-setting-label'>Chọn hình nền mới: </Col>
                        <Col>
                          <input
                            type="file"
                            id="input"
                            style={{float: 'left', alignContent: 'left'}}
                            onChange={(event) => this.onFileChanged(event.target.files[0])}/>
                        </Col>
                      </Row>
                    </Container>
                    <Container style={{padding: '1em'}}>
                      <Button
                        variant='info'
                        onClick={() => this.onSubmitSignSetting()}
                      >
                        Xác nhận
                      </Button>
                    </Container>
                  </Container>
                </Col>
              </Row>
            }
          </Col>
          <Col className='col-right'>
            <Container className='component-right'>
              {this.monitorItem(
                this.props.value.stationInfo,
                this.props.value.timer.setting,
                this.props.value.outputSignal,
                this.props.value.errorStatus,
                this.props.value.noteList,
                this.props.value.timer.current
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    )
  }
}